import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "hooks/useRequests";
import { ChaseAccount } from "models/chase";
import { AccountStatus, TreasurySession } from "models/treasury";

const useTreasuryManagement = (onBehalfOfUserId?: string) => {
    const sessionCreator = useAuthenticatedRequestCreator<TreasurySession, void>(
        () => ({
            method: 'post',
            url: "/treasury/session",
            data: { onBehalfOfUserId }
        }),
        [500]
    );

    const accountStore = useAuthenticatedRequestCreator<void, ChaseAccount>(
        (account) => ({
            method: 'post',
            url: '/treasury/created-account',
            data: { ...account, onBehalfOfUserId }
        }),
        [400]
    );

    const accountDelete = useAuthenticatedRequestCreator<void, string>(
        (externalAccountId) => ({
            method: 'post',
            url: '/treasury/accounts/deactivate',
            data: { externalAccountId }
        }),
        [400, 404]
    );

    const accountStatus = useAuthenticatedRequest<AccountStatus>({
        method: 'get',
        url: `/treasury/accounts/${onBehalfOfUserId}/status`
    });

    const accountSync = useAuthenticatedRequestCreator<void, void>(
        () => ({
            method: 'post',
            url: `/treasury/accounts/${onBehalfOfUserId}/sync`
        })
    );

    const { mutateAsync: createSession, isLoading, isError } = useMutation(sessionCreator);

    const { mutate: storeAccount } = useMutation(accountStore);

    const { mutateAsync: deleteAccount } = useMutation(accountDelete);

    const { mutateAsync: syncAccounts, isLoading: isSyncing } = useMutation(accountSync);

    const { data: remoteAccountStatus } = useQuery(['treasury', 'accounts', onBehalfOfUserId, "status"], accountStatus, {
        enabled: !!onBehalfOfUserId
    });

    return {
        createSession,
        storeAccount,
        deleteAccount,
        syncAccounts,
        remoteAccountStatus,
        isLoading,
        isSyncing,
        sessionError: isError
    };
};

export default useTreasuryManagement;