import { Box, HStack } from "@chakra-ui/react";
import { Button, Divider, Form, Input, Modal } from "antd";
import MultiValueDisplay from "components/MultiValueDisplay";
import usePackageTypeUpdate from "hooks/usePackageTypeUpdate";
import { FeeTier, FeeTierNames, FeeTierRange, PackageType, PackageTypeFees } from "models/packages";
import { FC, useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";
import { feeValidator } from "./helpers";

const TierFeeDisplay: FC<{
    packageType: PackageType,
    feeTier: FeeTier,
    onSuccess: () => Promise<any>,
    readOnly: boolean
}> = ({ packageType, feeTier, onSuccess, readOnly }) => {
    const fees = packageType.tierBasedFees.find(pt => pt.feeTier === feeTier);

    let feeText = "";
    if (!fees) {
        feeText = "Default Fees";
    } else if (
        (fees.percentageInPoints === 0 || fees.percentageInPoints === null) &&
        (fees.flatFeeInCents === 0 || fees.flatFeeInCents === null)
    ) {
        feeText = "No Fees";
    } else if (fees.percentageInPoints !== null && fees.flatFeeInCents !== null) {
        feeText = `${fees.percentageInPoints}% + ${centsToDollars(fees.flatFeeInCents)}`;
    } else if (fees.percentageInPoints !== null) {
        feeText = `${fees.percentageInPoints}%`;
    } else if (fees.flatFeeInCents != null) {
        feeText = centsToDollars(fees.flatFeeInCents);
    }

    return <TierFeeModal
        packageTypeId={packageType.id}
        packageTypeName={packageType.internalName}
        text={feeText}
        tier={feeTier}
        fees={fees}
        onSuccess={onSuccess}
        readOnly={readOnly}
    />;
};

const TierFeeModal: FC<{
    packageTypeId: string,
    packageTypeName: string;
    text: string,
    tier: FeeTier,
    fees: PackageTypeFees | undefined,
    onSuccess: () => Promise<any>,
    readOnly: boolean
}> = ({ packageTypeId, packageTypeName, text, tier, fees, onSuccess, readOnly }) => {
    const { setPackageTypeFee, deletePackageTypeFee } = usePackageTypeUpdate();
    const [visible, setVisible] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [allowSave, setAllowSave] = useState<boolean>(fees ? true : false);
    const [form] = Form.useForm();

    const initialValues = fees
        ? {
            ...fees,
            flatFee: fees.flatFeeInCents !== null
                ? centsToDollars(fees.flatFeeInCents, false)
                : null
        }
        : undefined;

    const opacity = fees ? 1 : 0.3;

    const reset = () => {
        form.resetFields();
        setVisible(false);
    }

    const setFee = () => {
        form.validateFields().then(async (data) => {
            setWorking(true);

            try {
                await setPackageTypeFee({
                    packageTypeId,
                    feeTier: tier,
                    percentageInPoints: data.percentageInPoints && data.percentageInPoints.toString().trim() !== ''
                        ? data.percentageInPoints
                        : null,
                    flatFeeInCents: data.flatFeeInCents >= 0
                        ? data.flatFeeInCents
                        : null
                });

                await onSuccess();
                setVisible(false);
            } finally {
                setWorking(false);
            }
        });
    }

    const deleteFee = async () => {
        try {
            setWorking(true);

            await deletePackageTypeFee(fees!.id);
            await onSuccess();
            setVisible(false);
        } finally {
            setWorking(false);
        }
    }

    const validateForm = () => {
        const percent = form.getFieldValue('percentageInPoints');
        const flatFee = form.getFieldValue('flatFeeInCents');

        setAllowSave((percent && percent.toString().trim() !== '') || flatFee >= 0);
    }
    return <>
        {!readOnly && <Button type="link" size="small" onClick={() => setVisible(true)}>
            <Box as="span" fontWeight={500} opacity={opacity}>{text}</Box>
        </Button>}
        {readOnly && <Box as="span" fontWeight={500} opacity={opacity}>{text}</Box>}
        <Modal
            open={visible}
            destroyOnClose
            closable={false}
            keyboard={false}
            maskClosable={false}
            title={<MultiValueDisplay
                title={`${packageTypeName} - ${FeeTierNames[tier]}`}
                subTitle={FeeTierRange[tier]} />}
            footer={[
                <HStack key={`${packageTypeId}|footer`} display='flex' justifyContent='space-between'>
                    <Button key='remove' type="primary" danger style={{ visibility: fees ? 'visible' : 'hidden' }} onClick={deleteFee} disabled={working}>
                        Remove Custom Fees
                    </Button>
                    <HStack>
                        <Button key='cancel' type="default" onClick={reset} disabled={working}>Cancel</Button>
                        <Button key='ok' type="primary" onClick={setFee} disabled={working || !allowSave}>Set Fees</Button>
                    </HStack>
                </HStack>
            ]}
        >
            <Form
                form={form}
                preserve={false}
                onFieldsChange={validateForm}
                initialValues={initialValues}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 5 }}
                layout="horizontal"
            >
                <Form.Item
                    label="Percent of Sale Price"
                    name='percentageInPoints'>
                    <Input suffix='%' type="number" placeholder="0.000" />
                </Form.Item>
                <Divider>and / or</Divider>
                <Form.Item
                    label="Flat Fee"
                    name='flatFee' rules={[{ transform: dollarsToCents, validator: (r, v) => feeValidator(form, r, v) }]}>
                    <Input prefix='$' type="number" placeholder="0.00" />
                </Form.Item>
                <Form.Item
                    hidden
                    name="flatFeeInCents">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    </>;
};

export default TierFeeDisplay;