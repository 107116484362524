import { CheckOutlined } from "@ant-design/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Button, Form, Input, Typography } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import BasicLoadingSpinner from "components/BasicLoadingSpinner";
import ACHAccounts from "components/customer/Profile/ACHAccounts";
import CustomerSelector from "components/CustomerSelector";
import { PayoutFees, useGetPayoutFees, useUpdatePayoutFees } from "hooks/usePayoutFees";
import useTreasuryAccounts from "hooks/useTreasuryAccounts";
import { ApplicationUser } from "models/applicationUsers";
import { FC, PropsWithChildren, Suspense, useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";
import { feeValidator } from "./PackageTypes/helpers";
import SettingsHeader from "./SettingsHeader";

const { Paragraph } = Typography;

const PayoutConfigSection = () => {
    return <>
        <SettingsHeader title="Customer Payout Requests" />

        <HStack display='flex' w='100%' alignItems='start'>
            <Suspense fallback={<BasicLoadingSpinner h="10rem" w="80%" />}>
                <PayoutFeesForm />
            </Suspense>
            <ACHAccountManager />
        </HStack>
    </>
}

const ACHAccountManager = () => {
    const [user, setUser] = useState<ApplicationUser>();
    const [syncing, setSyncing] = useState(false);
    const {
        data: accounts,
        refetch: refreshAccounts,
        isLoading: isLoadingAccounts
    } = useTreasuryAccounts(user?.id);

    const updateAccounts = async () => {
        await refreshAccounts();
    }

    return <Box>
        <Paragraph>
            Add ACH accounts on behalf of a user.
        </Paragraph>
        <VStack display='flex' alignItems='start'>
            <Box className="ach-account-mgr">
                <CustomerSelector
                    onSelected={setUser}
                    onClear={() => setUser(undefined)}
                    disabled={syncing}
                />
            </Box>
            {user?.id && <ACHAccounts
                onAccountCreated={updateAccounts}
                accounts={accounts ?? []}
                isLoadingAccounts={isLoadingAccounts}
                onBehalfOfUserId={user!.id}
                modalTitle={`Add Bank Account via J.P. Morgan Chase - ${user.firstName} ${user.lastName}`}
                onSyncing={setSyncing}
                onSyncComplete={updateAccounts}
            />}
        </VStack>
    </Box>
}

const HintLine = () => {
    return <Box style={{
        content: "''",
        width: '40%',
        height: '1px',
        background: '#ddd',
        display: 'block',
        position: 'relative',
        left: '-15px'
    }}></Box>;
}

const PayoutFormItem: FC<PropsWithChildren & { label: string }> = ({ children, label }) => {
    return <HStack w='100%' justifyContent='space-between'>
        <Text flexGrow={2}>{label}</Text>
        <HintLine />
        {children}
    </HStack>;
};

const PayoutFee: FC<{
    form: FormInstance<any>,
    label: string,
    name: string
}> = ({ form, label, name }) => {
    const rule = {
        transform: dollarsToCents,
        validator: (r: Rule, v: number) => feeValidator(form, r, v)
    };

    return <PayoutFormItem label={label}>
        <Form.Item name={name} rules={[rule]}>
            <Input prefix='$' type="number" placeholder="0.00" style={{ width: '100px' }} />
        </Form.Item>
        <Form.Item hidden name={`${name}InCents`}>
            <Input />
        </Form.Item>
    </PayoutFormItem>;
}

const PayoutFeesForm = () => {
    const { data: fees } = useGetPayoutFees();
    const { updatePayoutFees, isUpdating } = useUpdatePayoutFees();
    const [result, setResult] = useState<boolean>(false);
    const [form] = Form.useForm();

    const values = {
        ach: centsToDollars(fees!.achInCents, false),
        internationalWire: centsToDollars(fees!.internationalWireInCents, false),
        paypalDisplay: fees!.paypalDisplay!,
        wire: centsToDollars(fees!.wireInCents, false),
        upsOvernightDomestic: centsToDollars(fees!.check.upsOvernightDomesticInCents, false),
        upsOvernightInternational: centsToDollars(fees!.check.upsOvernightInternationalInCents, false),
        upsStandardDomestic: centsToDollars(fees!.check.upsStandardDomesticInCents, false),
        upsStandardInternational: centsToDollars(fees!.check.upsStandardInternationalInCents, false),
        achApprovalThreshold: centsToDollars(fees!.achApprovalThresholdInCents, false, false) // useGrouping: false
    };

    const submit = async () => {
        form.validateFields().then(async (data) => {
            const payload: PayoutFees = {
                achInCents: data.achInCents,
                internationalWireInCents: data.internationalWireInCents,
                paypalDisplay: data.paypalDisplay,
                wireInCents: data.wireInCents,
                check: {
                    upsOvernightDomesticInCents: data.upsOvernightDomesticInCents,
                    upsOvernightInternationalInCents: data.upsOvernightInternationalInCents,
                    upsStandardDomesticInCents: data.upsStandardDomesticInCents,
                    upsStandardInternationalInCents: data.upsStandardInternationalInCents
                },
                achApprovalThresholdInCents: data.achApprovalThresholdInCents
            }
            await updatePayoutFees(payload);
            setResult(true);
        });
    }

    return <Box w='40%' display='flex'>
        <Form
            form={form}
            initialValues={values}
            disabled={isUpdating}
        >
            <VStack display='flex' alignItems='start'>
                <Paragraph>
                    Configure the service fee to charge the customer based on the payout method.
                </Paragraph>
                <PayoutFee form={form} label="ACH" name="ach" />
                <PayoutFee form={form} label="International Wire" name="internationalWire" />
                <PayoutFormItem label={"PayPal**"}>
                    <Form.Item name="paypalDisplay">
                        <Input type="number" placeholder="0.00" suffix="%" style={{ width: '100px' }} />
                    </Form.Item>
                </PayoutFormItem>

                <HStack>
                    <Text>**PayPal collects their own fee, the value here is what to display to the customer</Text>
                </HStack>
                <HStack w='100%' justifyContent='center'>
                    <Text fontWeight={500}>Check Shipping Fees</Text>
                </HStack>

                <PayoutFee form={form} label="UPS Standard Domestic" name="upsStandardDomestic" />
                <PayoutFee form={form} label="UPS Standard Intl." name="upsStandardInternational" />
                <PayoutFee form={form} label="UPS Overnight Domestic" name="upsOvernightDomestic" />
                <PayoutFee form={form} label="UPS Overnight Intl." name="upsOvernightInternational" />

                <HStack w='100%' justifyContent='center'>
                    <Text fontWeight={500}>Payout Approvals</Text>
                </HStack>
                <PayoutFee form={form} label="ACH Approval Threshold" name="achApprovalThreshold" />

                <HStack>
                    <Button type="primary" style={{ margin: '20px 0px' }} onClick={submit}>Save Changes</Button>
                    {result && <CheckOutlined className="result-success" />}
                </HStack>
            </VStack>
        </Form>
    </Box>;
}

export default PayoutConfigSection;