import { Col, Layout, Row } from "antd";
import { PublicRoutes } from "appRoutePaths";
import { Link } from "react-router-dom";

import { Box } from "@chakra-ui/react";
import { NoPrintSx } from "models/common";
import "styles/footer.css";

const footerLogo = require("images/logo_white_and_blue.png");

const { Footer: AntDFooter } = Layout;

// TODO: Make the image of the footer not show up no mobile
// TODO: Convert to Chakra (or note that it won't be converted)
export const Footer = () => {
    return (
        <Box as={AntDFooter} bg="darkGray" color="white" w="100%" sx={NoPrintSx}>
            <Row className="footer-row">
                <Col span={8}>
                    <Link to={PublicRoutes.rootPath}>
                        <img
                            className="footer-logo"
                            src={footerLogo}
                            alt="footer logo"
                        />
                    </Link>
                </Col>
                <Col span={8}>
                    <Link to={PublicRoutes.privacyPolicy.url}>Terms & Conditions</Link>
                </Col>
                <Col span={8}>
                    <span>&copy; Copyright 2022</span>
                </Col>
            </Row>
        </Box>
    );
};