import { HStack, Text } from "@chakra-ui/react";
import { Alert, Button, Form, Input, InputNumber, Modal, Select } from "antd";
import useCountryOptions from "hooks/useCountryOptions";
import usePackageOwner from "hooks/usePackageOwner";
import useReturnCardsToCustomer, { ReturnCardsRequest, ReturnCardsResult } from "hooks/useReturnCardsToCustomer";
import { StaffPackage } from "models/packages";
import { ShipmentTemplates } from "models/shipping";
import { useEffect, useState } from "react";
import { centsToDollars } from "util/helpers";

const NoTemplate = 'N/T'
const ShippingMethods = Object.keys(ShipmentTemplates).map(key => ({
    label: `USPS Priority - ${ShipmentTemplates[key]}`,
    value: key
}));

const USPSFirstClass = { label: 'USPS First Class - Custom', value: NoTemplate };

const ReturnPackageModal = (props: {
    open: boolean;
    package: StaffPackage;
    onClose: (edited?: boolean) => void;
}) => {
    const countries = useCountryOptions();
    const { data: owner } = usePackageOwner(props.open ? props.package.id : undefined);
    const [form] = Form.useForm<ReturnCardsRequest>();
    const { returnCards, isLoading } = useReturnCardsToCustomer();
    const [result, setResult] = useState<ReturnCardsResult | undefined>();
    const [visibleDims, setVisibleDims] = useState(false);

    useEffect(() => {
        if (props.open && owner) {
            form.setFieldsValue({
                packageId: props.package.id,
                recipientName: `${owner?.firstName} ${owner?.lastName}`,
                address1: owner?.address?.addressLine1,
                address2: owner?.address?.addressLine2,
                city: owner?.address?.city,
                state: owner?.address?.state,
                zipCode: owner?.address?.zipCode,
                country: owner?.address?.country || "United States"
            });
        }
    }, [props.package.id, form, owner, props.open]);

    const onClose = () => {
        props.onClose();
        form.resetFields();
        setResult(undefined);
    }

    const submit = async (form: ReturnCardsRequest) => {
        const result = await returnCards(form);
        setResult(result);
    }

    return (
        <Modal
            title={`Returning Cards from Package ${props.package.friendlyId}`}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            open={props.open}
            onOk={form.submit}
            onCancel={onClose}
            okButtonProps={{ disabled: isLoading, hidden: !!result }}
            okText={isLoading ? "Creating Label..." : "OK"}
            cancelButtonProps={{ disabled: isLoading }}
            cancelText={result ? "Done" : "Cancel"}
        >
            {result && <Alert
                message="Return Label Created"
                showIcon
                type="success"
                description={<>
                    <Text>The tracking number for this return label is {result.trackingNumber}. The customer has been charged {centsToDollars(result.shippingCostInCents)} for shipping.</Text>
                    <Button type="link" href={result.labelUrl} style={{ padding: 0 }} target="_blank">Open Label PDF</Button>
                </>}
            />}

            {!result && <Form
                disabled={isLoading}
                form={form}
                layout="vertical"
                onFinish={submit}>
                <Form.Item name="packageId" hidden>
                    <Input />
                </Form.Item>
                <Form.Item label="Recipient Name" name="recipientName" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Address 1" name="address1" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="address2">
                    <Input />
                </Form.Item>
                <HStack alignItems="baseline">
                    <Form.Item label="City" name="city" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="State" name="state" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Zip" name="zipCode" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </HStack>
                <Form.Item label="Country" name="country" rules={[{ required: true }]}>
                    <Select
                        options={countries}
                    />
                </Form.Item>
                <Form.Item
                    label="Shipping Method"
                    name="parcelTemplate"
                    rules={[{ required: true, message: "You must select a shipment method!" }]}>
                    <Select
                        options={[...ShippingMethods, USPSFirstClass]}
                        onChange={v => setVisibleDims(v === NoTemplate)}
                    />
                </Form.Item>
                {visibleDims && <HStack w='100%' justifyContent='space-between' alignItems='baseline'>
                    <Form.Item name="lengthInInches" label="Length" rules={[{ required: true, type: 'number', min: 1, message: 'Length is required!' }]}>
                        <InputNumber placeholder="Inches" />
                    </Form.Item>
                    <Form.Item name="widthInInches" label="Width" rules={[{ required: true, type: 'number', min: 1, message: 'Width is required!' }]}>
                        <InputNumber placeholder="Inches" />
                    </Form.Item>
                    <Form.Item name="heightInInches" label="Height" rules={[{ required: true, type: 'number', min: 1, message: 'Height is required!' }]}>
                        <InputNumber placeholder="Inches" />
                    </Form.Item>
                    <Form.Item name="weightInOz" label="Weight" rules={[{ required: true, type: 'number', min: 1, message: 'Weight is required!' }]}>
                        <InputNumber placeholder="oz" step="0.1" />
                    </Form.Item>
                </HStack>}
            </Form>}
        </Modal>
    );
}

export default ReturnPackageModal;