import { PrinterFilled, PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Button, Divider, Radio, RadioChangeEvent, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { StaffRoutes } from "appRoutePaths";
import AuthenticatorBulkFulfillment from "components/staff/Shipping/AuthenticatorBulkFulfillment";
import CreationProgress from "components/staff/Shipping/CreationProgress";
import ShipmentBatchSummaryModel from "components/staff/Shipping/ShipmentBatchSummaryModal";
import ShipmentCreationModal from "components/staff/Shipping/ShipmentCreationModal";
import ShipmentSearch from "components/staff/Shipping/ShipmentSearch";
import useSeenShipmentLabel from "hooks/useSeenShipmentLabel";
import useShipmentBatches from "hooks/useShipmentBatches";
import { CssVariables, InitialPageRequest, PageRequest } from "models/common";
import { ShipmentBatch, ShipmentBatchLabel, ShipmentBatchType, ShipmentBatchTypeNames, ShipmentCardStatus } from "models/shipping";
import { FC, useState } from "react";
import { localizedDate } from "util/helpers";
import { StatusIcon } from "./ShipmentBatchCards";

enum BatchFilter {
    Unknown = 0,
    All,
    ToBePulled,
    ReadyToShip,
    Completed
}

const getFilterBy = (filter: BatchFilter): Record<string, string[]> | undefined => {
    switch (filter) {
        case BatchFilter.Completed:
            return { ShowCompleted: [''] };
        case BatchFilter.ToBePulled:
            return { ToBePulled: [''] };
        case BatchFilter.ReadyToShip:
            return { ReadyToShip: [''] };
        default:
            return undefined;
    }
}

const BatchLabels: FC<ShipmentBatch> = ({ id, labels, waitingOnLabels }) => {
    if (waitingOnLabels) {
        return <Button type="link" icon={<SyncOutlined spin />} style={{ cursor: "default" }} />
    }

    return <HStack>
        {labels.map((l: ShipmentBatchLabel) => (<ShipmentLabelIcon label={l} key={id} />))}
    </HStack>;
}

const ShipmentLabelIcon: FC<{ label: ShipmentBatchLabel }> = ({ label }) => {
    const [seen, setSeen] = useState(label.seen);
    const { seenLabel } = useSeenShipmentLabel();

    const sawLabel = async () => {
        const result = await seenLabel(label);
        setSeen(result);
    }

    const labelUrl = `/api/shipping/labels/${label.id}`;

    return <Box key={label.id}>
        {seen ? (
            <Button
                type="link"
                icon={<PrinterFilled
                    style={{ color: CssVariables.darkGreen }}
                />}
                rel='noopener, noreferrer'
                target="_blank"
                href={labelUrl} />
        ) : (
            <Button
                type="link"
                icon={<PrinterOutlined />}
                target="_blank"
                rel='noopener, noreferrer'
                href={labelUrl}
                onClick={sawLabel} />
        )}
    </Box>;
}

const columns: ColumnsType<ShipmentBatch> = [
    {
        title: "Name",
        dataIndex: "name",
        render: (name, rec) => <Button type="link" href={StaffRoutes.shipments.url(rec.id)}>{name}</Button>
    },
    {
        title: "Labels",
        dataIndex: "labels",
        render: (_, rec) => <BatchLabels {...rec} />
    },
    {
        title: "Generated",
        dataIndex: "generated",
        render: (g) => localizedDate(g)
    },
    {
        title: "Type",
        dataIndex: "type",
        render: (t: ShipmentBatchType) => ShipmentBatchTypeNames[t]
    },
    {
        title: "Orders",
        dataIndex: "orders",
        render: (_, rec) => <ShipmentBatchSummaryModel shipmentBatch={rec} />
    },
    {
        title: "Items",
        dataIndex: "items",
        render: (value, rec) => <Space>
            {value}
            {rec.hasShipmentsWithMissingCards && <Button
                type="link"
                icon={<StatusIcon status={ShipmentCardStatus.NotPulled} />}
                href={StaffRoutes.shipmentBatchMissingCards.url(rec.id)}
            />}
        </Space>
    },
    {
        title: "Pulled",
        dataIndex: "pulled",
        render: (_, rec) => <Button type="link" href={StaffRoutes.shipmentBatchCards.url(rec.id)}>
            {((rec.pulled / rec.items) * 100).toFixed(2)}%
        </Button>
    },
    {
        title: "Shipped",
        dataIndex: "shipped",
        render: (_, rec) => <Button type="link" href={StaffRoutes.shipmentBatchShipments.url(rec.id)}>
            {((rec.shipped / rec.orders) * 100).toFixed(2)}%
        </Button>
    }
];

const ShipmentBatches = () => {
    const [pageRequest, setPageRequest] = useState<PageRequest>(InitialPageRequest);
    const { isLoading, data } = useShipmentBatches(pageRequest);
    const { data: records, totalRecords, pageSize } = data || {};

    const tableChanged = (
        // pagination
        { current: newPage }: any,
        // filtering
        _: any,
        // sorting
        __: any
    ) => {
        setPageRequest({ ...pageRequest, page: newPage });
    };

    const applyFilter = (e: RadioChangeEvent) => {
        const filterBy = getFilterBy(e.target.value);
        setPageRequest({ ...pageRequest, page: 1, filterBy });
    }

    return (
        <Box p={50}>
            <VStack>
                <Box justifyContent='space-between' display='flex' style={{ width: '100%' }}>
                    <Box fontSize={36}>Shipment Batches</Box>
                    <VStack alignItems='end'>
                        <HStack>
                            <ShipmentCreationModal />
                            <Divider type="vertical" />
                            <Button type="link" size="large" href={StaffRoutes.labelReplacement.url}>Label Replacement</Button>
                            <Divider type="vertical" />
                            <Button size='large' type='link' href={StaffRoutes.deadMessages.url}>View Processing Errors</Button>
                        </HStack>
                        <HStack>
                            <AuthenticatorBulkFulfillment />
                            <ShipmentSearch />
                            <Radio.Group defaultValue={BatchFilter.All}
                                size='large'
                                buttonStyle='solid'
                                onChange={applyFilter}
                                style={{ alignSelf: 'end' }}>
                                <Radio.Button value={BatchFilter.All}>All</Radio.Button>
                                <Radio.Button value={BatchFilter.ToBePulled}>To Be Pulled</Radio.Button>
                                <Radio.Button value={BatchFilter.ReadyToShip}>Ready To Ship</Radio.Button>
                                <Radio.Button value={BatchFilter.Completed}>Completed</Radio.Button>
                            </Radio.Group>
                        </HStack>
                    </VStack>
                </Box>
                <CreationProgress />
                <Box w='100%'>
                    <Table
                        id="shipment-batches-table"
                        rowKey={r => r.id}
                        columns={columns}
                        dataSource={records}
                        loading={isLoading}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: pageRequest.page,
                            pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            hideOnSinglePage: true
                        }}
                    />
                </Box>
            </VStack>
        </Box>
    )
}

export default ShipmentBatches;